import request from '@/api/request.js'

// 获取全部商品接口
export function getGoodList (data) {
  return request({
    url: '/api/spu/search',
    method: 'POST',
    data
  })
}
// 获取我收藏的商品接口
export function getMyGoodList (data) {
  return request({
    url: '/api/spu/list/favorite',
    method: 'GET',
    data
  })
}
// 获取分类接口
export function getCategory (data) {
  return request({
    url: '/api/category/list',
    method: 'POST',
    data
  })
}
// 新增接口
export function addGood (data) {
  return request({
    url: '/api/spu/add',
    method: 'POST',
    data
  })
}
// 下架接口
export function offspu (data) {
  return request({
    url: '/api/spu/off',
    method: 'POST',
    data
  })
}
// 上架接口
export function onspu (data) {
  return request({
    url: '/api/spu/on',
    method: 'POST',
    data
  })
}
// 收藏接口
export function addFavorite (data) {
  return request({
    url: '/api/spu/addFavorite',
    method: 'POST',
    data
  })
}
// 商品详细接口
export function getGoodDetail (data) {
  return request({
    url: '/api/spu/detail',
    method: 'POST',
    data
  })
}
// 取消收藏接口
export function cancelFavorite (data) {
  return request({
    url: '/api/spu/cancelFavorite',
    method: 'POST',
    data
  })
}
// 编辑接口
export function editGood (data) {
  return request({
    url: '/api/spu/edit',
    method: 'POST',
    data
  })
}

// 新增分类接口
export function addCategory (data) {
  return request({
    url: '/api/category/add',
    method: 'POST',
    data
  })
}
// 编辑分类接口
export function editCategory (data) {
  return request({
    url: '/api/category/edit',
    method: 'POST',
    data
  })
}
// 删除分类接口
export function delCategory (data) {
  return request({
    url: '/api/category/delete',
    method: 'POST',
    data
  })
}

// 更改排序
export function changeSort (data) {
  return request({
    url: '/api/spu/changeSort',
    method: 'POST',
    data
  })
}

// 查询sku
export function listSku (data) {
  return request({
    url: '/api/spu/listSku',
    method: 'POST',
    data
  })
}

// 编辑接口
export function editSku (data) {
  return request({
    url: '/api/spu/editSku',
    method: 'POST',
    data
  })
}


// 复制给团长
export function copy2Retailer (data) {
  return request({
    url: '/api/spu/copy2Retailer',
    method: 'POST',
    data
  })
}

// 查询运费模板
export function listDeliveryTemplate (data) {
  return request({
    url: '/api/deliveryTemplate/list',
    method: 'POST',
    data
  })
}

// 解析网盘
export function parsePan (data) {
  return request({
    url: '/api/spu/pan',
    method: 'POST',
    data
  })
}

// 上传商品
export function uploadGoods (data) {
  return request({
    url: '/api/spu/upload2ShopPlatform',
    method: 'POST',
    data
  })
}

// 查询日志
export function getlogs (data) {
  return request({
    url: '/api/spu/logs',
    method: 'POST',
    data
  })
}


// 完善商品资料
export function completeGoods (data) {
  return request({
    url: '/api/spu/completePlatformInfo',
    method: 'POST',
    data
  })
}

// 获取群接龙的分类列表
export function listQunjielongCategory (data) {
  return request({
    url: '/api/category/listQunjielongCategory',
    method: 'POST',
    data
  })
}