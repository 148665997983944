<template>
  <div>
  <el-dialog title="添加分类" :visible.sync="classflag" :before-close="handleClose" width="680px">
        <el-input v-model="formClass.name" prefix-icon="el-icon-edit" autocomplete="off" placeholder="请输入类目名称" style="width: 280px; margin-right: 10px"></el-input>
        <el-button type="primary" :loading="logining" @click="handleSubmit">增加分类</el-button>
        <div class="classList">
          <el-table
          :data="tableData"
          border
          :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
          style="width: 100%">
        <el-table-column
        prop="name"
        label="类目名称"
      >
        </el-table-column>
        <el-table-column
        label="操作"
        header-align="center" align="center"
        width="140">
      <template slot-scope="scope">
        <!-- <el-button @click="handleEdit(scope.row)"  type="text" style="margin-right:10px">修改</el-button> -->
        <el-popconfirm title="确定要删除吗？" @confirm="handlDels(scope.row)">
          <el-button slot="reference" type="text">删除</el-button>
        </el-popconfirm>
      </template>
    </el-table-column>
      </el-table>
          </div>
        <!-- <div class="midCss">
          <el-button type="primary" :loading="logining" @click="handleSubmit('formAdd')">确认提交</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </div> -->

    </el-dialog>
  </div>
</template>

<script>
import { addCategory, editCategory, delCategory, getCategory } from "@/api/good";
export default {
  name: 'classInfo',
  props: ['isShow'],
  data () {
    return {
      formClass: {
        name: ''
      },
      tableData: [],
      logining: false,
      classflag: false
    }
  },
  created: function () { 
    this.getCategoryList({"role":window.sessionStorage.getItem("role")})
  },
  watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.classflag = newVal
      }
    }
  },
  methods: {
    handleClose(){
      this.classflag = false
      this.$emit('onCancal', false)
    },
    handleEdit(row){
       
    },
    handlDels(row){
      var that = this
      var objs = {}
      objs.id = row.id
      console.log(row)
      delCategory(objs).then(res => {
        that.$message({
          message: '删除成功',
          type: 'success'
        });
        that.getCategoryList()
      })

    },
    getCategoryList(){
      var that = this
      const param = {
        "role": window.sessionStorage.getItem("role")
      }
      getCategory(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSubmit(){
      let that = this
      that.logining = true
      if(that.formClass.name == ''){
        this.$message.warn('请输入类目名称')
        return
      }
      that.formClass.role = window.sessionStorage.getItem("role")
          // if(that.formClass.id){
          //   editCategory(that.formClass).then(res => {
          //     that.logining = false
          //     if (parseInt(res.code) === 200) {
          //          that.$message({
          //             message: '修改成功',
          //             type: 'success'
          //           });
          //         setTimeout(() => {
          //           that.classflag = false
          //         }, 500)
          //         that.getCategoryList()
          //     } else {
          //       this.$message.error(res.message)
          //     }
          //   })

          // }else{
      addCategory(that.formClass).then(res => {
        that.logining = false
        if (parseInt(res.code) === 200) {
            that.$message({
                message: '新增成功',
                type: 'success'
              });
            that.formClass.name = ''
            that.getCategoryList()
        } else {
          this.$message.error(res.message)
        }
      })
          // }
    
    }
  }
}
</script>

<style scoped>
.midCss {
  text-align: left;
  display: block;
  padding-left: 120px;
  padding-top: 20px;
}
.tips {
  font-size: 12px;
  color: #999;
  padding-left: 120px;
}
</style>
