import request from '@/api/request.js'

// 授权门店列表
export function getMall (data) {
  return request({
    url: '/api/shop/authShops',
    method: 'POST',
    data
  })
}
// 获取所有可授权的平台
export function getPlatforms (data) {
  return request({
    url: '/api/shop/platforms',
    method: 'post',
    data
  })
}

// 解除授权
export function deleteShop(data) {
  return request({
    url: '/api/shop/delete',
    method: 'post',
    data
  })
}


export function setConfig(data) {
  return request({
    url: '/api/shop/config',
    method: 'post',
    data
  })
}

// 添加开团易授权
export function addKaituanyi(data) {
  return request({
    url: '/api/shop/authKaituanyi',
    method: 'post',
    data
  })
}

// 添加群接龙
export function addQunjielong(data) {
  return request({
    url: '/api/shop/addQunjielong',
    method: 'post',
    data
  })
}

// 切换快团团版本
export function switchKttVersion(data) {
  return request({
    url: '/api/shop/switchKttVersion',
    method: 'post',
    data
  })
}
