<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          label-width="70px"
        >
          <el-form-item label="商品名称" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.goodsName"
              clearable
              placeholder="商品名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="规格编码" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.specCode"
              clearable
              placeholder="规格编码"
            ></el-input>
          </el-form-item>

          <el-form-item label="供应商" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.supplierName"
              clearable
              placeholder="供应商"
            ></el-input>
          </el-form-item>

          <el-form-item label="屏蔽订单" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.disableOrderDownload"
              clearable
              placeholder="全部"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作废订单" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.autoInvalidateOrder"
              clearable
              placeholder="全部"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="已复制" style="margin-bottom: 10px">
            <el-select v-model="searchForm.copied" clearable placeholder="全部">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="已匹配" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.matched"
              clearable
              placeholder="全部"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-button @click="createSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button @click="chongzhi">重置</el-button>
        </el-form>
      </div>

      <div class="mainbox">
        <div class="buttons">
          <el-checkbox
            :value="expandAll"
            style="margin-right: 20px"
            @change="expandTable()"
            >展开规格</el-checkbox
          >

          <el-button type="primary" plain @click="() => (syncDialogFlag = true)"
            size="mini"
            >下载平台商品</el-button
          >

          <el-button type="primary" plain @click="showCopyDialog()"
            size="mini"
            >复制到基础商品</el-button
          >

          <el-button type="primary"
                      size="mini"
           plain @click="batchDel">批量删除</el-button>

          <el-tooltip
            class="item"
            effect="dark"
            content="自动屏蔽的订单不会出现在订单列表里面"
            placement="top-start"
          >
            <el-button type="primary" plain @click="() => batchAutoDisable(1)"
              >批量自动屏蔽</el-button
            >
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="自动作废的订单可以在以作废订单列表中操作还原"
            placement="top-start"
          >
            <el-button type="primary" plain
            size="mini"
             @click="showInvalidateDialog()"
              >批量自动作废</el-button
            >
          </el-tooltip>

          <el-button type="primary" plain @click="() => batchAutoDisable(0)"
            size="mini"
            >取消自动屏蔽</el-button
          >

          <el-button
            type="primary"
            plain
            size="mini"
            @click="() => batchCancelAutoInvalidate()"
            >取消自动作废</el-button
          >
        </div>

        <el-table
          ref="theTable"
          :data="tableData"
          :default-expand-all="expandAll"
          border
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          stripe
          style="width: 100%"
          @selection-change="handleSpuSelect"
        >
          <el-table-column
            type="selection"
            width="50"
            header-align="center"
            align="center"
          >
          </el-table-column>          

          <el-table-column prop="goodsName" label="商品"> </el-table-column>

          <el-table-column prop="categoryName" label="分类"> </el-table-column>

          <el-table-column prop="platformName" width="100" label="授权渠道">
          </el-table-column>

          <el-table-column prop="shopName" label="授权账号" width="180">
          </el-table-column>

          <el-table-column
            prop="copied"
            width="80"
            align="center"
            label="已复制到基础商品"
          >
            <template slot-scope="scope">
              <i v-if="scope.row.copied == 1" class="el-icon-check yes"></i>
            </template>
          </el-table-column>

          <el-table-column width="80" align="center" label="开启自动屏蔽订单">
            <template slot-scope="scope">
              <i
                v-if="scope.row.disableOrderDownload == 1"
                class="el-icon-check yes"
              ></i>
            </template>
          </el-table-column>

          <el-table-column width="80" align="center" label="开启自动作废订单">
            <template slot-scope="scope">
              <i
                v-if="scope.row.autoInvalidateOrder == 1"
                class="el-icon-check yes"
              ></i>
            </template>
          </el-table-column>

          <el-table-column prop="supplierName" label="供应商">
          </el-table-column>

          <el-table-column
            type="expand"
            width="50"
            header-align="center"
            align="center"
            label="查看"
          >
            <template slot-scope="scope">
              <el-table
                :header-cell-style="{background:'#eef1f6',color:'#303030'}"
                class="table-in-table"
                :show-header="true"
                :data="scope.row.skuList"
                style="width: 100%"
                row-key="id"
                border
              >
                <!-- <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
            </el-table-column> -->

                <el-table-column prop="specName" label="规格">
                </el-table-column>

                <el-table-column prop="specCode" label="规格编码">
                </el-table-column>

                <el-table-column prop="salePrice" label="销售价">
                </el-table-column>

                <el-table-column
                  prop="copied"
                  width="80"
                  align="center"
                  label="复制"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      content="已复制到基础商品"
                      placement="top"
                      @click.stop.prevent
                    >
                      <i
                        v-if="scope.row.copied == 1"
                        class="el-icon-check yes"
                      ></i>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="disableOrderDownload"
                  label="自动屏蔽订单"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      content="已设置自动屏蔽订单"
                      placement="top"
                      @click.stop.prevent
                    >
                      <i
                        v-if="scope.row.disableOrderDownload == 1"
                        class="el-icon-check yes"
                      ></i>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="autoInvalidateOrder"
                  label="自动作废订单"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      content="已设置自动作废订单"
                      placement="top"
                      @click.stop.prevent
                    >
                      <i
                        v-if="scope.row.autoInvalidateOrder == 1"
                        class="el-icon-check yes"
                      ></i>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="匹配规则" prop="matchKey">
                </el-table-column>

                <el-table-column align="center" label="已匹配基础商品" prop="skuName">
                </el-table-column>

                <el-table-column width="250" label="操作" style="float: left">
                  <template slot-scope="scope2">
                    <el-button
                      type="text"
                      size="small"
                      @click="
                        showMatchSkuDialog(
                          scope.row,
                          scope.$index,
                          scope2.$index
                        )
                      "
                      >匹配商品</el-button
                    >
                    <el-button
                      v-if="scope2.row.disableOrderDownload == 0"
                      type="text"
                      size="small"
                      @click="() => disableDownload(scope2.row, 1)"
                      >屏蔽订单</el-button
                    >
                    <el-button
                      v-if="scope2.row.disableOrderDownload == 1"
                      type="text"
                      size="small"
                      @click="() => disableDownload(scope2.row, 0)"
                      >取消屏蔽</el-button
                    >
                    <el-button
                      v-if="scope2.row.autoInvalidateOrder == 0"
                      type="text"
                      size="small"
                      @click="() => autoInvalidateOrder(scope2.row, 1)"
                      >自动作废订单</el-button
                    >
                    <el-button
                      v-if="scope2.row.autoInvalidateOrder == 1"
                      type="text"
                      size="small"
                      @click="() => autoInvalidateOrder(scope2.row, 0)"
                      >取消自动作废</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" width="150" label="下载时间">
          </el-table-column>
        </el-table>
        <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            @size-change="changeSize"
            :page-sizes="[20, 40, 50, 100]"
            :total="total"
          >
          </el-pagination>
        </div>
        <!-- </el-tab-pane>
    </el-tabs> -->
      </div>
    </div>

    <el-dialog
      title="下载平台商品"
      :visible.sync="syncDialogFlag"
      width="600px"
    >
      <el-form :model="shop" label-position="right" label-width="100px">
        <el-form-item label="选择渠道：">
          <el-select
            v-model="shopName"
            placeholder="请选择渠道"
            clearable
            @change="changeShop"
          >
            <el-option
              v-for="item in shops"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="下载策略：">
          <el-select
            v-model="stragetyName"
            placeholder="下载策略"
            clearable
            @change="changeStragety"
          >
            <el-option key="ORDER" label="按订单支付时间" value="ORDER">
            </el-option>
            <el-option key="GOODS" label="按商品创建时间" value="GOODS">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="时间范围："
          prop="date"
          style="margin-bottom: 10px"
        >
          <el-date-picker
            v-model="date"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="syncDialogFlag = false">取消</el-button>
        <el-button type="primary" @click="download()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="复制到基础商品"
      :visible.sync="copyDialogFlag"
      width="600px"
    >
      <el-form :model="copied" label-position="right" label-width="200px">
        <el-form-item label="选择商品供应商：">
          <el-select
            v-model="supplierName"
            placeholder="请选择供应商"
            clearable
            @change="changeSupplier"
            filterable
            :filter-method="supplierFilter"
          >
            <el-option
              v-for="item in supplierOrgs"
              :key="item.targetOrgId"
              :label="item.name"
              :value="item.targetOrgId"
              :disabled="item.cooperation==0"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择商品类目：">
          <el-select
            v-model="globalCategoryName"
            placeholder="请选择商品类目"
            clearable
            @change="changeGlobalCategory"
          >
            <el-option
              v-for="item in globalCategories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="自动建立匹配关系：">
          <el-radio-group v-model="autoMatch">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <label class="tips"
            >选择自动建立匹配关系，渠道订单会自动匹配关联已复制的基础商品</label
          >
        </el-form-item>

        <el-form-item label="自动更新订单：" v-if="autoMatch == 1">
          <el-radio-group v-model="updateOrder">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <label class="tips"
            >包含该商品的48小时内未处理、待审核的订单会被自动更新</label
          >
        </el-form-item>

        <el-form-item label="复制过去自动上架：">
          <el-radio-group v-model="onShelfRightNow">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <label class="tips"
            >复制商品自动上架，只有上架状态的商品才会触发订单自动匹配</label
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyDialogFlag = false">取消</el-button>
        <el-button type="primary" @click="copy()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="匹配基础商品"
      :visible.sync="matchDialogFlag"
      width="600px"
    >
      <el-form :model="sku" label-position="right" label-width="200px">
        <el-form-item label="商品：">{{ sku.goodsName }}</el-form-item>
        <el-form-item label="规格：" v-if="sku.specName">{{
          sku.specName
        }}</el-form-item>
        <el-form-item label="编码：" v-if="sku.specCode">{{
          sku.specCode
        }}</el-form-item>

        <el-form-item label="选择商品供应商：">
          <el-select
            v-model="supplierName"
            placeholder="请选择供应商"
            clearable
            @change="changeSupplierThenSku"
            filterable
            :filter-method="supplierFilter"
          >
            <el-option
              v-for="item in supplierOrgs"
              :key="item.targetOrgId"
              :label="item.name"
              :value="item.targetOrgId"
              :disabled="item.cooperation==0"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择商品：">
          <el-select
            v-model="skuName"
            placeholder="请选择商品"
            clearable
            @change="changeSku"
            filterable
            :filter-method="skuFilter"
          >
            <el-option
              style="max-width: 600px"
              v-for="item in skus"
              :key="item.id"
              :label="item.spuName + (item.name ? '/' : '') + item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="更新该商品的订单：">
          <el-radio-group v-model="updateOrder">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="() => (matchDialogFlag = false)">取消</el-button>
        <el-button type="primary" @click="matchSku">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="批量自动作废订单"
      :visible.sync="showInvalidateOrderFlag"
      width="600px"
    >
      <el-form :model="copied" label-position="right" label-width="200px">
        <el-form-item label="自动更新订单：">
          <el-radio-group v-model="updateOrder" v-if="autoMatch == 1">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <label class="tips"
            >包含该商品的48小时内未处理、待审核的订单会被自动作废</label
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showInvalidateOrderFlag = false">取消</el-button>
        <el-button type="primary" @click="batchAutoInvalidate()"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  transformNormal,
  page,
  list,
  remove,
  sync,
  match,
  disableOrders,
  invalidateOrders,
  cancelInvalidateOrders,
} from "@/api/platformsku";

import { getMall } from "@/api/shop";
import { listSupplier } from "@/api/supplier";
import { listSku } from "@/api/good";
import { dictConfig, globalCategory } from "@/api/common";
import passWord from "@/components/password";
import subClass from "@/components/getClass";
import backImg from "@/assets/logofang.png";

let moment = require("moment");

export default {
  name: "Main",
  components: { passWord, subClass },
  data() {
    return {
      searchForm: {
        goodsName: "",
        specCode: "",
        disableOrderDownload: null,
        autoInvalidateOrder: null,
        matched: "",
      },
      classflag: false,
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      syncDialogFlag: false,
      shopName: "",
      shopId: "",
      shops: [],
      shop: {},
      stragetyName: "",
      stragety: "GOODS",
      date: [],
      selectSpu: [],
      copyDialogFlag: false,
      copied: {},
      supplierOrgs: [],
      supplierId: "",
      supplierName: "",
      globalCategories: [],
      globalCategoryName: "",
      globalCategoryId: "",
      onShelfRightNow: 1,
      forecd: 0,
      expandAll: false,
      sku: {
        id: "",
        goodsName: "",
        specName: "",
        specCode: "",
      },
      matchDialogFlag: false,
      skus: [],
      skuId: "",
      skuName: "",
      spuIndex: "",
      skuIndex: "",
      updateOrder: 1,
      autoMatch: 1,
      showInvalidateOrderFlag: false,
    };
  },
  methods: {
    matchSku() {
      const that = this;
      const param = {
        id: that.sku.id,
        skuId: that.skuId,
        updateOrder: that.updateOrder,
      };
      match(param).then((res) => {
        if (res.code === 200) {
          that.matchDialogFlag = false;
          that.sku = res.data;
          that.$message.success(res.message);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    showMatchSkuDialog(row, spuIndex, skuIndex) {
      this.spuIndex = spuIndex;
      this.skuIndex = skuIndex;
      this.sku = {
        id: row.id,
        goodsName: row.goodsName,
        specName: row.skuList[skuIndex].specName,
        specCode: row.skuList[skuIndex].specCode,
      };
      this.matchDialogFlag = true;
      this.supplierName = "";
      this.skuName = "";
      this.supplierFilter(null);
    },
    batchAutoDisable(val) {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请先选择要操作的商品");
        return;
      }
      var ids = [];
      this.selectSpu.forEach((spuId) => {
        this.tableData.forEach((tr) => {
          if (tr.id == spuId) {
            tr.skuList.forEach((sku) => {
              ids.push(sku.id);
            });
          }
        });
      });
      const param = {
        ids: ids,
        yesOrNo: val,
      };
      disableOrders(param).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getTopSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    showInvalidateDialog() {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请先选择要操作的商品");
        return;
      }
      this.showInvalidateOrderFlag = true;
    },
    batchAutoInvalidate() {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请先选择要操作的商品");
        return;
      }
      var ids = [];
      this.selectSpu.forEach((spuId) => {
        this.tableData.forEach((tr) => {
          if (tr.id == spuId) {
            tr.skuList.forEach((sku) => {
              ids.push(sku.id);
            });
          }
        });
      });
      const param = {
        ids: ids,
        updateOrder: this.updateOrder,
      };
      invalidateOrders(param).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getTopSearch();
          this.showInvalidateOrderFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    batchCancelAutoInvalidate() {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请先选择要操作的商品");
        return;
      }
      var ids = [];
      this.selectSpu.forEach((spuId) => {
        this.tableData.forEach((tr) => {
          if (tr.id == spuId) {
            tr.skuList.forEach((sku) => {
              ids.push(sku.id);
            });
          }
        });
      });
      const param = {
        ids: ids,
        yesOrNo: 0,
      };
      cancelInvalidateOrders(param).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getTopSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    batchDel() {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请选择要删除的商品");
        return;
      }
      var spuKeys = [];
      this.tableData.forEach((t) => {
        if (this.selectSpu.indexOf(t.id) >= 0) {
          spuKeys.push(t.spuKey);
        }
      });
      remove(spuKeys).then((res) => {
        if (res.code === 200) {
          this.$message.success("删除成功");
          this.getTopSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    forArr(arr, isExpand) {
      arr.forEach((i) => {
        this.$refs.theTable.toggleRowExpansion(i, isExpand);
      });
    },
    expandTable() {
      this.expandAll = !this.expandAll;
      this.forArr(this.tableData, this.expandAll);
    },
    download() {
      const param = {
        shopId: this.shopId,
        strategy: this.stragety,
        startTime: moment(this.date[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.date[1]).format("YYYY-MM-DD HH:mm:ss"),
      };
      sync(param).then((res) => {
        if (res.code === 200) {
          this.syncDialogFlag = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      });
    },
    copy() {
      const that = this;
      var param = {
        ids: this.selectSpu,
        supplierOrgId: this.supplierId,
        supplierOrgName: this.supplierName,
        globalCategoryId: this.globalCategoryId,
        status: this.onShelfRightNow,
        autoMatch: this.autoMatch,
        updateOrder: this.updateOrder,
      };
      transformNormal(param).then((res) => {
        if (res.code === 200) {
          that.copyDialogFlag = false;
          this.$message.success("复制成功");
          this.getTopSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    supplierFilter(val) {
      listSupplier({ name: val }).then((res) => {
        if (res.code === 200) {
          this.supplierOrgs = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    skuFilter(val) {
      this.getSkus(this.supplierId, val);
    },
    showCopyDialog() {
      if (this.selectSpu.length == 0) {
        this.$message.warning("请选择要复制的商品");
        return;
      }
      this.supplierFilter(null);
      this.globalCategory();
      this.copyDialogFlag = true;
    },
    globalCategory() {
      globalCategory().then((res) => {
        if (res.code === 200) {
          this.globalCategories = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changeGlobalCategory(category) {
      this.globalCategoryId = category;
    },
    changeSupplier(supplier) {
      this.supplierId = supplier;
    },
    changeSupplierThenSku(supplier) {
      this.changeSupplier(supplier);
      this.getSkus(supplier, null);
    },
    getSkus(supplierOrgId, name) {
      this.skus = [];
      this.skuId = "";
      this.skuName = "";
      const param = {
        supplierOrgId: supplierOrgId,
        name: name,
        status: 1,
        role: window.sessionStorage.getItem("role"),
      };
      listSku(param).then((res) => {
        if (res.code === 200) {
          this.skus = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changeSku(skuId) {
      this.skuId = skuId;
    },
    changeSize(newSize) {
      var that = this;
      if (that.pageSize === newSize) {
        return;
      }
      that.page = 1;
      that.pageSize = newSize;
      that.getTopSearch();
    },
    changePage(newPage) {
      var that = this;
      if (that.page === newPage) {
        return;
      }
      that.page = newPage;
      that.getTopSearch();
    },
    handleSpuSelect(event) {
      this.selectSpu = event.map((s) => s.id);
    },
    getTopSearch() {
      var that = this;
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      if (datas.autoInvalidateOrder == "") {
        datas.autoInvalidateOrder = null;
      }
      if (datas.disableOrderDownload == "") {
        datas.disableOrderDownload = null;
      }

      page(datas).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.tableData = res.data.rows;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    createSearch() {
      this.page = 1;
      this.getTopSearch();
    },
    chongzhi() {
      this.classCur = 0;
      this.page = 1;
      this.searchForm = {};
    },
    disableDownload(row, val) {
      if (val == 1 && row.matched != 1) {
        this.$message.warning("请先匹配商品");
        return;
      }
      const param = {
        ids: [row.id],
        yesOrNo: val,
      };
      disableOrders(param).then((res) => {
        if (res.code === 200) {
          row.disableOrderDownload = val;
          if (val == 1) {
            row.autoInvalidateOrder = 1 - val;
          }
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    autoInvalidateOrder(row, val) {
      if (val == 1 && row.matched != 1) {
        this.$message.warning("请先匹配商品");
        return;
      }
      const param = {
        ids: [row.id],
        yesOrNo: val,
      };
      invalidateOrders(param).then((res) => {
        if (res.code === 200) {
          row.autoInvalidateOrder = val;
          if (val == 1) {
            row.disableOrderDownload = 1 - val;
          }
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changeStragety(value) {
      this.stragety = value;
    },
    changeShop(shopId) {
      this.shopId = shopId;
    },
    loadShops() {
      var that = this;
      getMall({
        pageNo: 1,
        pageSize: 200,
      }).then((res) => {
        if (res.code === 200) {
          that.shops = res.data.rows.filter((s) => s.authStatus === 1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  mounted() {
    this.getTopSearch();
    this.loadShops();

    const end = new Date();
    const start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 12);
    this.date = [start, end];
  },
};
</script>

<style scoped>
.el-form-item__label {
  font-size: 12px;
}
.el-button--primary {
  color: #fff;
  background-color: #07c160;
  border-color: #07c160;
}
.el-button--primary.is-plain {
    color: #409EFF;
    background: #ecf5ff;
    border-color: #b3d8ff;
}
.biaoticss {
  padding-bottom: 15px;
  font-size: 18px;
  padding-left: 10px;
}
.biaoticss span {
  float: right;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #07c160;
  color: #fff;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}
.classFun {
  padding: 0px 12px 10px;
  overflow: hidden;
}
.classFun strong {
  font-size: 14px;
  font-weight: 350;
  color: #606266;
  padding-right: 12px;
}
.classFun span {
  padding: 5px 20px;
  border: 1px solid #eee;
  line-height: 44px;
  color: #333;
  margin-right: 10px;
  cursor: pointer;
}
.classFun span.xuan {
  background-color: #07c160;
  color: #fff;
  border-color: #07c160;
}
.classObj {
  float: right;
  line-height: 44px;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.classObj i {
  font-size: 12px;
  padding-right: 5px;
}
.classList {
  padding-top: 10px;
}
.imgSize {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #eee;
}
.biaoti {
  display: block;
  line-height: 18px;
  height: 36px;
  overflow: hidden;
}
.price strong {
  color: #ff7200;
}
.buttons {
  margin-bottom: 10px;
}
.yes {
  font-size: 16px;
  color: #07c160;
}
.tips {
  float: left;
  font-size: 12px;
  color: #999;
}
</style>