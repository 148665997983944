import request from '@/api/request.js'


export function page (data) {
  return request({
    url: '/api/platformSku/page',
    method: 'POST',
    data
  })
}

export function list (data) {
    return request({
        url: '/api/platformSku/list',
        method: 'POST',
        data
    })
}

export function remove (data) {
    return request({
        url: '/api/platformSku/delete',
        method: 'POST',
        data
    })
}


export function sync (data) {
    return request({
        url: '/api/platformSku/sync',
        method: 'POST',
        data
    })
}  

export function match (data) {
    return request({
      url: '/api/platformSku/match',
      method: 'POST',
      data
    })
  }
  
export function disableOrders (data) {
    return request({
        url: '/api/platformSku/disableOrders',
        method: 'POST',
        data
    })
}

// 作废订单
export function invalidateOrders (data) {
    return request({
        url: '/api/platformSku/invalidateOrders',
        method: 'POST',
        data
    })
}

// 批量取消作废
export function cancelInvalidateOrders (data) {
    return request({
        url: '/api/platformSku/cancelInvalidateOrders',
        method: 'POST',
        data
    })
}


export function transformNormal (data) {
    return request({
        url: '/api/platformSku/transformNormal',
        method: 'POST',
        data
    })
}